import './js/main.js';
import './template.scss';
import { Shop } from './js/shopify';

jQuery('.t-slider').slick({
	autoplay: false,
	autoplaySpeed: 4000,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows: true,
	prevArrow: '<button type="button" class="t-slider-nav slick-prev"><span class="linea-arrows-slim-left"></span></button>',
	nextArrow: '<button type="button" class="t-slider-nav slick-next"><span class="linea-arrows-slim-right"></span></button>',
});
// Brands/Clients Slider
jQuery('.clients-slider').slick({
	autoplay: true,
	autoplaySpeed: 4000,
	slidesToShow: 5,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	responsive: [
		{
			breakpoint: 999,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 2,
				infinite: true,
			}
		},
		{
			breakpoint: 770,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 599,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
});